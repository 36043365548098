<template>
    <div class="vm-banner vm-product-banner d-flex align-center">
        <div class="swiper-container" id="lines-swiper">
            <div class="swiper-wrapper">
                <div class="swiper-slide d-flex align-center" v-for="(item,i) in slides" :key="i" :id="'r-slider-' + i">
                    <div class="vm-banner-bg" :style="{ backgroundImage: 'url(' + require('@/assets/images/products/reach/'+ item.src) + ')' }">
                        <img src="@/assets/images/products/reach/vuma-reach-banner.jpg" alt="">
                    </div>
                    <v-container>
                        <div class="header-wrap">
                            <!-- <div class="small-header">> Fibre to the home</div> -->
                            <div class="bigger-header" v-html="item.title"></div>
                            <div class="sub-header" v-html="item.subHeader"></div>    
                        </div>

                        <v-btn rounded outlined dark @click.prevent="$emit('scrollTo')">
                          See available packages
                        </v-btn>

                    </v-container>
                </div>
            </div>
            <!-- <div class="swiper-button-next" id="lines-next"></div>
            <div class="swiper-button-prev" id="lines-prev"></div> -->
            <div class="swiper-pagination"></div>
        </div>
    </div>
</template>

<script>
    import Swiper from "swiper";
    import "swiper/css/swiper.min.css";
  export default {
    data () {
      return {
        dialog: false,
        slides: [
            {
              title: 'Reach for <br><strong>the future.</strong>',
              subHeader: 'Enabling you with affordable, reliable and fast fibre.',
              src: 'banners/reach-banner-1.jpg'
            },
            {
              title: 'Reach for <br><strong>the extraordinary.</strong>',
              subHeader: 'Gain access to infinite possibilities and <br class="br-desktop">reach for them.',
              src: 'banners/reach-banner-2.jpg'
            },
            {
              title: 'Reach <br><strong>for more.</strong>',
              subHeader: 'Giving you the opportunity to more from home.',
              src: 'banners/reach-banner-3.jpg'
            }
        ]
      }
    },
    mounted(){
        var mySwiper = new Swiper('#lines-swiper', {
            preloadImages: true,
            pagination: {
                el: '.swiper-pagination',
                type: 'bullets',
                clickable: true
            },
            paginationClickable: true,
            loop: true,
            speed: 600,
            autoplay: {
                delay: 5000,
                disableOnInteraction: false
            },
            centeredSlides: true,
            spaceBetween: 0
        });
    }
  }
</script>

<style lang="scss">
  // #r-slider-1 .sub-header{ max-width: 600px; }
  @media screen and (max-width: 1280px){
    .br-desktop{ display: none; }
  }
</style>