<template>
    <div class="vm-section vm-fibre-process">
        <v-container class="text-center">
            <h2>Installing Vuma Fibre Reach</h2>
            <v-row class="vm-process-blocks">
                <v-col class="col-6 col-sm-4 col-lg-5ths">
                    <div class="vm-b-img">
                        <img src="@/assets/images/products/core/vuma-fibre-process-1.svg" alt="Vuma Fibre Process">
                    </div>
                    <h4>Planning for the future</h4>
                    <p>Once we have received enough community support, we’ll plan and design the fibre network for your suburb.</p>
                    <!-- <v-btn href="#" text class="mt-0">
                      > Read more
                    </v-btn> -->
                </v-col>
                <v-col class="col-6 col-sm-4 col-lg-5ths">
                    <div class="vm-b-img">
                        <img src="@/assets/images/products/core/vuma-fibre-process-2.svg" alt="Vuma Fibre Process">
                    </div>
                    <h4>Informing your neighbourhood</h4>
                    <p>As soon as we receive permission from your city council, we will inform you that your fibre roll-out will soon begin.</p>
                    <!-- <v-btn href="#" text class="mt-0">
                      > Read more
                    </v-btn> -->
                </v-col>
                <v-col class="col-6 col-sm-4 col-lg-5ths">
                    <div class="vm-b-img">
                        <img src="@/assets/images/products/core/vuma-fibre-process-3.svg" alt="Vuma Fibre Process">
                    </div>
                    <h4>Building a network of connections</h4>
                    <p>We will begin the ground work to run fibre past your home, getting you fibre-ready for installation.</p>
                    <!-- <v-btn href="#" text class="mt-0">
                      > Read more
                    </v-btn> -->
                </v-col>
                <v-col class="col-6 col-sm-4 col-lg-5ths">
                    <div class="vm-b-img">
                        <img src="@/assets/images/products/core/vuma-fibre-process-4.svg" alt="Vuma Fibre Process">
                    </div>
                    <h4>Restore</h4>
                    <p>
                        We care about your suburb and home. Therefore, our restore process simply means that we will restore your property to the same state it was before deployment.
                    </p>
                    <!-- <v-btn href="#" text class="mt-0">
                      > Read more
                    </v-btn> -->
                </v-col>
                <v-col class="col-6 col-sm-4 col-lg-5ths">
                    <div class="vm-b-img">
                        <img src="@/assets/images/products/core/vuma-fibre-process-5.svg" alt="Vuma Fibre Process">
                    </div>
                    <h4>Install and reach for more</h4>
                    <p>
                        The network is live in your area and you can place your order from your preferred Internet Service Provider, allowing you to reach for more.
                    </p>
                    <!-- <v-btn href="#" text class="mt-0">
                      > Read more
                    </v-btn> -->
                </v-col>
            </v-row>

            <v-btn @click="$emit('scrollTo')" rounded outlined>
                See available packages
            </v-btn>

        </v-container>
    </div>
</template>

<script>
  export default {
    name: 'ReachFibreProcess'
  }
</script>

<style lang="scss">
    .vm-fibre-process{
        background-color: #EE008C;
        color: #fff;
        &.vm-section{
            padding-top: 5rem;
            padding-bottom: 5rem;
        }
        // NEST
        // .container{ max-width: 1700px; }
        h2{
            margin-bottom: 3rem;
        }
        h4{
            font-size: 22px;
            line-height: 1.2;
            margin-bottom: 1rem;
            font-family: "Core Sans R 55", "Roboto", sans-serif !important;
            font-weight: inherit;
        }
        .vm-b-img{
            width: 150px;
            margin: auto;
            margin-bottom: 1.5rem;
        }
        p{
            line-height: 1.2;
            padding: 0 1rem;
            // font-size: 18px;
            margin-bottom: 0;
        }
        .theme--light.v-btn {
            color: #fff;
            margin-top: 3rem;
        }
        .vm-process-blocks{
            // NEST
            .v-btn{ font-size: 18px !important; }
        }
    }
</style>