<template>
  <div>
    <div
      color="black"
      class="form-content"
      justify="center"
    >
      <v-dialog
        persistent
        max-width="800px"
        :value="value"
        @input="$emit('input', $event)"
      >
        <v-card>
          <v-card-title class="form-header-wrapper">
            <div>
              <div class="heading-title-wrapper">
                <h4 class="business-form-heading">Business Fibre Feasibility Form</h4>
              </div>
                <v-btn
                  class="close-form-feasibility"
                  icon
                  dark
                  @click.native="$emit('input', false)"
                >
                  <v-icon>mdi-close</v-icon>
                </v-btn>
            </div>
          </v-card-title>

          <v-form
            ref="form"
            class="mt-3 mb-1 mx-5"
            lazy-validation
          >
            <v-row class="mb-0 pb-0 pl-0">
              <v-col cols="12" md="6" class="pt-1 pb-0">
                <p class="date-text">Date</p>
                <p class="form-date">{{date}}</p>
              </v-col>
            </v-row>
            <v-row>
              <v-card-text class="pt-0 pb-1">
                <h3 class="business-form-title">
                  Location Details
                </h3>
                <v-divider class="mt-2 mb-5" />
              </v-card-text>

              <v-row class="mx-4">
                <v-col cols="12" md="6" class="py-0 mb-4">
                  <v-text-field
                    required
                    :rules="rules.required"
                    v-model="landlordName"
                    label="Landlord Name *"
                  />
                </v-col>

                <v-col cols="12" md="6" class="py-0 mb-4">
                  <v-text-field
                    required
                    :rules="rules.mobileNumberRule"
                    v-model="landlordContact"
                    label="Landlord Contact Number *"
                  />
                </v-col>

                <v-col cols="12" md="6" class="py-0 mb-4">
                  <v-text-field
                    required
                    :rules="rules.emailRules"
                    v-model="landlordEmail"
                    label="Landlord Email *"
                  />
                </v-col>

                <v-col cols="12" md="6" class="py-0 mb-4">
                  <v-text-field
                    required
                    :rules="rules.required"
                    v-model="officePark"
                    label="Office Park *"
                  />
                </v-col>

                <v-col cols="12" md="6" class="pt-3 pb-0">
                  <p class="office-title">Office Park Address *</p>
                  <p class="office-park-address">{{addressTitle}}</p>
                </v-col>

                <v-col cols="12" md="6" class="pb-0">
                  <p class="gps-coordinates-title">
                    GPS Co.Ords (Decimal Degrees) *
                  </p>
                  <p class="geo-location-data">
                    {{`${this.latitudeValue}, 
                    ${this.longitudeValue}`}}
                  </p>
                </v-col>
              </v-row>
            </v-row>

            <v-row class="mt-3">
              <v-card-text class="pt-0 pb-1 mt-0">
                <h3 class="business-form-title">
                  Client Details (End User)
                </h3>
                <v-divider class="mt-2 mb-5" />
              </v-card-text>

              <v-row class="mx-3">
                <v-col cols="12" md="6" class="py-0">
                  <v-text-field
                    required
                    :rules="rules.required"
                    v-model="clientName"
                    label="Client Name *"
                  />
                </v-col>

                <v-col cols="12" md="6" class="py-0">
                  <v-text-field
                    required
                    :rules="rules.mobileNumberRule"
                    v-model="clientContactNumber"
                    label="Client Contact Number *"
                  />
                </v-col>

                <v-col cols="12" md="6" class="py-0 mt-4">
                  <v-text-field
                    required
                    :rules="rules.required"
                    v-model="clientAddress"
                    label="Client Address Details *"
                  />
                </v-col>
              </v-row>
            </v-row>

            <v-row class="mt-3">
              <v-card-text class="pt-0 pb-1 mt-8">
                <h3 class="business-form-title">
                  Site Details
                </h3>
                <v-divider class="mt-2 mb-5" />
              </v-card-text>

              <v-row class="mx-3">
                <v-col cols="12" md="6" class="py-0">
                  <v-select
                    required
                    :rules="rules.required"
                    :items="serviceSpeedsValues"
                    v-model="serviceSpeeds"
                    label="Service Speeds *"
                  ></v-select>
                </v-col>

                <v-col cols="12" md="6" class="py-0">
                  <v-select
                    required
                    :rules="rules.required"
                    label="Site Type *"
                    v-model="siteType"
                    :items="siteTypes"
                  />
                </v-col>

                <v-col cols="12" md="6" class="py-0">
                  <v-text-field
                    required
                    :rules="rules.required"
                    :disabled="disableUnitNumbersField"
                    label="Unit Number(s) at the Site *"
                    v-model="unitNumbers"
                  />
                </v-col>

                <v-col cols="12" md="6" class="py-0">
                  <v-checkbox
                    v-model="existingServiceProviders"
                    label="Are there any FNO/ISPs on site currently?"
                  />
                </v-col>

                <v-col cols="12" class="py-0">
                  <v-checkbox
                    :rules="rules.required"
                    auto-grow
                    v-model="consent"
                    label="I consent to sharing my information with Vuma and their third parties (ISP and registered contractor)"
                  />
                </v-col>
                <v-alert
                  dense
                  outlined
                  type="error"
                  v-if="hasError"
                >
                  There was a problem submitting your form. Please try again later.
                </v-alert>
              </v-row>
            </v-row>

            <v-divider class="mt-9 mb-2" />
            <v-row class="px-1 mt-5 mb-5">
              <v-spacer />
              <v-btn
                v-if="!hasError"
                class="submit-button white--text"
                color="#EE008C"
                :disabled="!valid"
                @click="submitFormDetails"
                >Submit
              </v-btn>
              <v-btn
                v-else
                class="submit-button white--text"
                color="#EE008C"
                :disabled="true"
                @click="submitFormDetails"
                >Submit
              </v-btn>
            </v-row>
          </v-form>

          <v-row>
            <v-row class="mt-3"> </v-row>
          </v-row>
          <v-card-actions>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import {
  VUMA_BUSINESS_FEASIBILITY_API_KEY
} from '@/store/constants-vuma-key.js'
export default {
  name: 'CustomDialog',
  props: {
    locationDetails: {
      type: Object,
      default: () => {
        return {}
      },
    },
    value: {
      type: Boolean,
      default: 'value',
    },
    user: {
      type: String,
      default: 'user',
    },
  },
  data() {
    return {
      businessFeasibilityRequestId: 'business-feasibility-request',
      valid: true,
      showAlert: false,
      alertColor: 'primary',
      alertIcon: 'error',
      alertMessage: '',
      rules: {
        required: [(value) => !!value || 'Required.'],
        mobileNumberRule: [
          (value) => !!value || 'Required',
          (value) =>
            /^(\+27|0)[0-9]{9}$/.test(
              value
            ) || 'Contact number is not valid, please enter only numbers',
          (value) =>
            (value && value.length <= 10) ||
            'The contact number must be less than 10 characters',
        ],
        emailRules: [
          (value) => !!value || 'Required',
          (value) =>
            /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(value) ||
            'E-mail must be valid',
        ],
      },
      date: null,
      ispName: '',
      officePark: '',
      officeParkAddress: '',
      gpsCoOrds: '',
      landlordName: '',
      landlordContact: '',
      landlordEmail: '',
      clientName: '',
      clientContactNumber: '',
      clientAddress: '',
      serviceSpeeds: '',
      unitNumbers: '',
      disableUnitNumbersField: false,
      existingServiceProviders: false,
      consent: false,
      siteTypes: [
        'Commercial - Office Park',
        'Industrial - Industrial Spaces',
        'Retail - Shops',
        'Stand Alone - One Site',
      ],
      siteType: '',
      serviceSpeedsValues: [
        '20/20 Mbps',
        '50/50 Mbps',
        '100/100 Mbps',
        '200/200 Mbps',
        '1000/200 Mbps',
      ],
      address: null,
      latitude: null,
      longitude: null,
      hasError: false,
    }
  },
  mounted() {

    this.date = new moment().format('YYYY-MM-DD')
    this.ispName = window.localStorage.getItem(
      `${process.env.VUE_APP_NODE_ENV}_ORG_NAME`
    )
  },
  updated() {
    if (this.$refs.form) {
      this.valid = this.$refs.form.validate()
    }
  },
  computed: {
    region() {
      const vumaRegion = this.locationDetails.vuma_region
      switch (vumaRegion) {
        case 'Vuma Village Regional Office':
        case 'Secondary Cities Regional Office':
          return 'all'
        case 'Ekurhuleni Regional Office':
        case 'EKU':
        case 'Tshwane Regional Office':
          return 'north'
        case 'Coastal Region':
        case 'Cape Town Regional Office':
        case 'CPT':
        case 'Durban Regional Office':
        case 'DBN':
          return 'coastal'
        default:
          return 'central'
      }
    },
    latitudeValue() {
      return this.$store.state.latitude
    },
    longitudeValue() {
      return this.$store.state.longitude
    },
    addressTitle() {
      return this.$store.state.address
    }
  },
  watch: {
    siteType(val) {
      if (val === 'Stand Alone - One Site') {
        this.unitNumbers = '-'
        this.disableUnitNumbersField = true
      } else {
        this.unitNumbers = ''
        this.disableUnitNumbersField = false
      }
    },
  },
  created() {},
  methods: {
    submitFormDetails() {
      this.$store.commit('setIsLoading', true)
      const { latitude, longitude, address } = this.$store.state

      const latitudeValue = latitude.toString()
      const longitudeValue = longitude.toString()
      const addressValue = address

      this.gpsCoOrds = latitudeValue.concat(',', longitudeValue);
      this.officeParkAddress = addressValue

      let data = {
        date: new moment().format('YYYY-MM-DD h:mm:ss'),
        isp_name: 'Vuma Website - No ISP Name',
        agent_name: 'Vuma Website - No Agent Name',
        agent_contact: 'Vuma Website - No Agent Contact Number',
        office_park: this.officePark,
        office_park_address: this.officeParkAddress,
        gps_co_ords: this.gpsCoOrds,
        landlord_name: this.landlordName,
        landlord_contact: this.landlordContact,
        landlord_email: this.landlordEmail,
        client_name: this.clientName,
        client_contact_number: this.clientContactNumber,
        client_address: this.clientAddress,
        service_speeds: this.serviceSpeeds,
        unit_numbers: this.unitNumbers,
        existing_service_providers: this.existingServiceProviders,
        site_type: this.siteType,
        region_name: this.region,
        form_name: 'BUSINESS_FEASIBILITY',
      }

      const url = `${process.env.VUE_APP_PANGEA_BASE_URL}/orders/business-feasibility-form-submission/`
      
      const headers = { 'Content-Type': 'application/json' }
      if (process.env.VUE_APP_ENV === 'production') {
        headers.apiKey = VUMA_BUSINESS_FEASIBILITY_API_KEY
      }
      const config = {
        headers: headers
      }

      this.axios
        .post(url, data, config)
        .then((response) => {
          if (response.status === 200) {
            this.$store.commit('setIsLoading', true)
            this.$router.push({ name: 'SmiThankYou' })
            this.$store.commit('setIsLoading', false)
          }
        })
        .catch((error) => {
          this.$store.commit('setHasError', true)
          this.$store.commit('setIsLoading', false)
          this.hasError = true
          error = null
        })
    },
    clearForm() {
      this.showAlert = false
      this.alertColor = 'primary'
      this.alertIcon = 'error'
      this.alertMessage = ''
      this.officeParkAddress = ''
      this.gpsCoOrds = ''
      this.officePark = ''
      this.landlordName = ''
      this.landlordContact = ''
      this.landlordEmail = ''
      this.clientName = ''
      this.clientContactNumber = ''
      this.clientAddress = ''
      this.serviceSpeeds = ''
      this.unitNumbers = ''
      this.existingServiceProviders = false
      this.siteType = ''
    },
  },
}
</script>

<style lang="scss" scoped>
.office-title {
  margin: 0;
  padding: 0;
  height: 0.2rem;
  font-size: 1rem;
  color: #383838;
}
.form-wrapper {
  margin: 0 1.2rem;
}
.form-header-wrapper {
  display: grid;
  background-color: #e2e2e2;
  color: deeppink;
  font-size: 1rem;
}
.close-form-feasibility {
  float: right;
}
.user-note {
  font-size: 0.7rem;
}
.gps-coordinates-title {
  font-size: 1rem;
  padding: 0;
  margin: 0;
  color: #383838;
  height: 0.6rem;
}
.business-form-heading {
  font-weight: 800;
  margin-top: 0.7rem;
}
.geo-location-data {
  font-size: 0.7em;
  padding: 1.1rem 0 0;
  font-weight: 600;
}
.office-park-address {
  font-size: 0.9rem;
  padding: 1.5rem 0 0;
  font-weight: 600;
}
.form-date {
  font-size: 0.9rem;
  padding: 0;
  font-weight: 600;
}
.date-text {
  font-size: 0.9rem;
  padding: 0;
  margin: 0.2rem 0;
}
.heading-title-wrapper {
  float:left; 
  margin-right:20px;
  width:450px;
}
@media screen and (max-width: 600px) {
  .heading-title-wrapper {
    margin-right: 0;
    width: 70%;
    border: 0;
  }
}
@media screen and (max-width: 425px) {
  .business-form-heading {
    font-size: 1.8rem;
    margin-top: 0.6rem;
  }
  .gps-coordinates-title {
    font-size: 0.9rem;
  }
  .geo-location-data {
    font-size: 0.9rem;
    padding: 1.3rem 0 0;
  }
}
</style>
