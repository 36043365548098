<template>
<div id="high-speed">
  <v-card class="vm-tabs-product">
    <v-tabs
      v-model="tab"
      dark
      grow
    >
      <v-tab key="tab-core-entertain">Entertainment</v-tab>
      <v-tab key="tab-core-connect">Connect</v-tab>
      <v-tab key="tab-core-choose">Save</v-tab>
      <v-tab key="tab-core-work">Work</v-tab>
      <v-tab key="tab-core-learn">Learn</v-tab>
    </v-tabs>

    <v-tabs-items v-model="tab" class="vm-reach-tabs">
		<v-tab-item key="tab-core-entertain">
			<div class="vm-section vm-tab-items d-flex align-center">
				<v-container>
					<v-row>
						<v-col class="col-12 col-sm-5 col-md-5 col-lg-4">
							<h2>Be entertained</h2>
							<p class="vm-tab-content">
								Stream or download the latest movies and series over your unlimited internet connection - and enjoy new experiences with the family at home.
							</p>
							<v-btn rounded outlined @click.prevent="$emit('scrollTo')">
								See available packages
							</v-btn>
						</v-col>
					</v-row>
				</v-container>
				<div class="vm-tab-bg" :style="{ backgroundImage: 'url(' + require('@/assets/images/products/reach/tabs/reach-entertainment.jpg') + ')' }">
					<img src="@/assets/images/products/reach/tabs/reach-entertainment.jpg" alt="">
				</div>
			</div>
		</v-tab-item>
		<v-tab-item key="tab-core-connect">
			<div class="vm-section vm-tab-items d-flex align-center">
				<v-container>
					<v-row>
						<v-col class="col-12 col-sm-5 col-md-5 col-lg-4">
							<h2>Build connections</h2>
							<p class="vm-tab-content">
								Stay connected with friends and family on your favourite social media channels.
							</p>
							<v-btn rounded outlined @click="$emit('scrollTo')">
								See available packages
							</v-btn>
						</v-col>
					</v-row>
				</v-container>
				<div class="vm-tab-bg" :style="{ backgroundImage: 'url(' + require('@/assets/images/products/reach/tabs/reach-connect.jpg') + ')' }">
					<img src="@/assets/images/products/reach/tabs/reach-connect.jpg" alt="">
				</div>
			</div>
		</v-tab-item>
		<v-tab-item key="tab-core-choose">
			<div class="vm-section vm-tab-items d-flex align-center">
				<v-container>
					<v-row>
						<v-col class="col-12 col-sm-5 col-md-5 col-lg-4">
							<h2>Plan for the future</h2>
							<p class="vm-tab-content">
								Spend less on cellular data and save more for the things your family really needs.
							</p>
							<v-btn rounded outlined @click="$emit('scrollTo')">
								See available packages
							</v-btn>
						</v-col>
					</v-row>
				</v-container>
				<div class="vm-tab-bg" :style="{ backgroundImage: 'url(' + require('@/assets/images/products/reach/tabs/reach-save.jpg') + ')' }">
					<img src="@/assets/images/products/reach/tabs/reach-save.jpg" alt="">
				</div>
			</div>
		</v-tab-item>
		<v-tab-item key="tab-core-work">
			<div class="vm-section vm-tab-items d-flex align-center">
				<v-container>
					<v-row>
						<v-col class="col-12 col-sm-5 col-md-5 col-lg-4">
							<h2>Find new opportunities</h2>
							<p class="vm-tab-content">
								With an unlimited internet connection, take charge of your future from home and reach for more. Start an online business, bank, market and plan for success.
							</p>
							<v-btn rounded outlined @click="$emit('scrollTo')">
								See available packages
							</v-btn>
						</v-col>
					</v-row>
				</v-container>
				<div class="vm-tab-bg" :style="{ backgroundImage: 'url(' + require('@/assets/images/products/reach/tabs/reach-work.jpg') + ')' }">
					<img src="@/assets/images/products/reach/tabs/reach-work.jpg" alt="">
				</div>
			</div>
		</v-tab-item>
		<v-tab-item key="tab-core-learn">
			<div class="vm-section vm-tab-items d-flex align-center">
				<v-container>
					<v-row>
						<v-col class="col-12 col-sm-5 col-md-5 col-lg-4">
							<h2>Access online education</h2>
							<p class="vm-tab-content">
								Access to unlimited educational online content gives you and your family the power to create, complete homework assignments and conduct project research from the safety of your home.
							</p>
							<v-btn rounded outlined @click="$emit('scrollTo')">
								See available packages
							</v-btn>
						</v-col>
					</v-row>
				</v-container>
				<div class="vm-tab-bg" :style="{ backgroundImage: 'url(' + require('@/assets/images/products/reach/vuma-reach-banner.jpg') + ')' }">
					<img src="@/assets/images/products/reach/vuma-reach-banner.jpg" alt="">
				</div>
			</div>
		</v-tab-item>
    </v-tabs-items>
  </v-card>
</div>
</template>

<script>
  export default {
    data () {
      return {
        tab: null
      }
    },
  }
</script>


<style lang="scss">
	.vm-tabs-product{
		.theme--dark.v-tabs{
			&> .v-tabs-bar {
			    background-color: #EE008C;
			    height: 85px;
			}
			.v-tab{
				font-size: 25px;
				font-family: "Core Sans R 35", "Roboto", sans-serif !important;
				text-transform: none;
				/*min-width: 360px;*/
				width: 20%;

				letter-spacing: 0.1em;
				color: #fff !important;
				&.v-tab--active{
					font-family: "Core Sans R 55", "Roboto", sans-serif !important;
					&::before { opacity: 0.24 !important; }
					// &:not(:focus)::before { opacity: 0.24 !important; }
					&:after{
						content: "";
						display: block;
						width: 0; 
						height: 0; 
						border-left: 20px solid transparent;
						border-right: 20px solid transparent;
						border-top: 20px solid #EE008C;
						position: absolute;
						bottom: -20px;
						left: 50%;
					}
				}
				&:hover::before {
				    opacity: 0.24;
				}
			}
			.v-tabs-slider-wrapper{ display: none; }
		}
		.vm-tab-items{
			color: #000;
			
		    .vm-tab-bg{
			    &:before{ display: none; } 
		    }	
		}

		.v-btn{
			&:before{ opacity: 0; }
        	&:hover{
        		color: #fff;
        		border-color: #ee008c;
        		background-color: #ee008c;
        		// &:before{ opacity: 1; }
        	}
		}
	} 
</style>