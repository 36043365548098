<template>
  <div class="cont-pages">
    <ReachBanner @scrollTo="scrollCoverage"/>
    <ProductCoverage @scrollTo="scrollCoverage"/>
    <ProductReachAbout @scrollTo="scrollCoverage"/>
    <ProductReachTabs @scrollTo="scrollCoverage"/>
    <FibreProcess @scrollTo="scrollCoverage"/>
    <div class="reach-shop text-center">
      <v-container>
        <div>
          <h2>Order Now</h2>
          <p>Check your preferred Internet Service Providers website for more information on current deals and
            pricing.</p>
          <p>To order, select a fibre internet package from one of our Internet Service Provider Partners below.</p>
        </div>
        <v-row class="reach-products-wrap">
          <v-col class="col-12 col-sm-6 col-md-4" v-for="(item, i) in reachproducts" :key="item.title">
            <v-card class="reach-card">
              <div class="reach-img d-flex align-center justify-center" :class="{'reduce-width': item.reduceWidth}">
                <img :src="item.img" :alt="item.title"/>
              </div>
              <v-card-text class="reach-card__text">
                <p class="text--primary">Free Installation
                  <v-icon size="36px">mdi-check</v-icon>
                </p>
                <p class="text--primary">Free Activation
                  <v-icon size="36px">mdi-check</v-icon>
                </p>
                <p class="text--primary">Free Router
                  <v-icon size="36px">mdi-check</v-icon>
                </p>
              </v-card-text>
              <v-card-actions>
                <v-btn class="order-button" :href="item.href" target="_blank" rounded outlined>
                  Order Now
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </div>
</template>

<script>
import $ from 'jquery'
import ReachBanner from '@/components/Products/Reach/Banner'
import ProductReachAbout from '@/components/Products/Reach/About'
import ProductReachTabs from '@/components/Products/Reach/Tabs'
import FibreProcess from '@/components/Products/Reach/FibreProcess'
import ProductCoverage from '@/components/Products/Core/Coverage'

export default {
  name: 'VumaReach',
  data: () => ({
    reachproducts: [
      {
        title: 'MWEB',
        img: require('@/assets/images/products/reach/partners/mweb.png'),
        href: 'https://www.mweb.co.za/fibre-vuma-reach',
        reduceWidth: false,
      },
      {
        title: 'Home Connect',
        img: require('@/assets/images/products/reach/partners/home-connect.png'),
        href: 'https://www.home-connect.co.za/vumatel-reach/',
        reduceWidth: false,
      },
      {
        title: 'Cool Ideas',
        img: require('@/assets/images/products/reach/partners/cool-ideas.png'),
        href: 'https://coolideas.co.za/',
        reduceWidth: false,
      },
      {
        title: 'Vodacom',
        img: require('@/assets/images/products/reach/partners/vodacom.png'),
        href: 'https://www.vodacom.co.za/vodacom/fibre/sales/leads',
        reduceWidth: false,
      },
      {
        title: 'Afrihost',
        img: 'https://vumareach.co.za/wp-content/uploads/2020/04/afrihost-portrait.jpg',
        href: 'https://afrihost.com/fibre/provider/vuma-reach?utm_source=vuma_reach&utm_medium=website&utm_campaign=pure-fibre&utm_content=affiliate-link',
        reduceWidth: true,
      },
      {
        title: 'WebAfrica',
        img: 'https://vumareach.co.za/wp-content/uploads/2020/04/webafrica-portrait.png',
        href: 'https://www.webafrica.co.za/vuma-reach-fibre',
        reduceWidth: true,
      },
      {
        title: 'Fly Cool Internet',
        img: require('@/assets/images/products/reach/partners/fly-cool-internet.png'),
        href: 'https://fly.crm.com/?utm_source=Vumatel&utm_campaign=website',
        reduceWidth: false,
      },
      {
        title: 'SA Digital Villages',
        img: require('@/assets/images/products/reach/partners/sa-digital-villages.jpg'),
        href: 'https://www.sadv.co.za/',
        reduceWidth: false,
      },
      {
        title: 'Hypa',
        img: require('@/assets/images/products/reach/partners/hypa.jpg'),
        href: 'https://hypa.co.za/',
        reduceWidth: false,
      },
    ],
  }),
  components: {
    ReachBanner,
    ProductReachAbout,
    ProductReachTabs,
    FibreProcess,
    ProductCoverage,
  },
  mounted () {
    $('html').css('overflow', 'auto')
    $('.vuma-menu, .menu-overlay').removeClass('menu-show')
  },
  methods: {
    scrollCoverage () {
      let target = 'html, body'

      $(target).stop().animate({
        'scrollTop': $(target).offset().top - 50,
      }, 500, 'swing')
    },
  },
  metaInfo () {
    return {
      title: 'Vuma Fibre Reach | Affordable And Reliable Prepaid Internet',
      meta: [
        {
          name: 'description',
          content: 'Vuma Fibre Reach is designed to give you world-class fibre internet without the fuss of long-term contracts. Get affordable, fast and reliable fibre today.',
        },
        { property: 'og:title', content: 'Vuma Fibre Reach | Affordable And Reliable Prepaid Internet' },
        { property: 'og:site_name', content: 'Vumatel' },
        { property: 'og:type', content: 'website' },
      ],
    }
  },
}
</script>

<style lang="scss">
.reach-shop {
  padding-top: 5rem;
  padding-bottom: 5rem;

  .container {
    max-width: 1000px;

    .text-center {
      max-width: 750px;
      margin: 0 auto 3rem;

      h2 {
        margin-bottom: 1.5rem;
      }
    }

    h2 {
      margin-bottom: 2rem;
    }
  }

  .v-card {
    -webkit-box-shadow: none;
    box-shadow: none;
    border: 1px solid #ddd;
    padding: 1.8rem;
    border-radius: 35px !important;
    height: auto;

    .display-vm {
      font-size: 44px !important;
      font-family: "Core Sans R 55", "Roboto", sans-serif !important;
      font-weight: inherit;
      line-height: 0.7;
      letter-spacing: 0.0073529412em !important;
      color: #ee008c;
      margin-bottom: 1.5rem;
    }

    .v-card__text {
      padding: 1.5rem;
      text-align: center;

      p {
        font-size: 17px;
        line-height: 1;
        margin-bottom: 0.5rem;

        .v-icon {
          color: #ee008c;
          font-size: inherit !important;
        }
      }
    }

    .v-card__actions {
      padding: 0.35rem 1.5rem 1.5rem;
      justify-content: center;

      a {
        text-decoration: none;
        font-size: 16px !important;
        font-family: "Core Sans R 55", "Roboto", sans-serif !important;
        color: #ee008c;
      }

      .order-button:hover {
        color: #fff;
      }
    }

    .reach-img {
      padding: 0.35rem 0;
      width: 100%;
      height: auto;

      &.reduce-width {
        img {
          max-width: 40%;
        }
      }
    }
  }

  .reach-products-wrap {
    max-width: 1150px;
    width: 100%;
    margin: 2rem auto 0;
  }
  .reach-card {
    height: 100% !important; // Override v-card's height: auto
    display: flex;
    flex-direction: column;
    &__text {
      flex: 1;
    }
  }
}

</style>