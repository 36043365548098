<template>
    <div class="vm-section vm-core d-flex align-center">
        <v-container class="text-center">
            <v-row class="justify-center">
                <v-col class="col-12 col-md-7">
                    <h1>What is Vuma Fibre Reach?</h1>
                    <p>
                        <strong>It’s affordable, fast and reliable fibre internet that will change the way you and your community connect to the world.</strong>
                        <br><br>
                        Vuma Fibre Reach is a prepaid fibre service designed to give you access to affordable and reliable fibre in the safety and comfort of your home, without the fuss of long-term contracts and the stress of high costs.
                        <br><br>
                        We believe that every South African deserves access to high-speed, reliable and open-access internet. As we begin to pass into new areas, we look forward to providing you and your community with the fibre you need to reach for the future.
                        <br><br>
                        <strong>How does it work?</strong> Fibre cables, of no monetary value, are run past your home. These cables connect you, through an Internet Service Provider, to world-class internet connectivity. 
                    </p>
                    <v-btn @click="$emit('scrollTo')" rounded outlined class="pink-outline">
                      See available packages
                    </v-btn>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>
  export default {
    name: 'ProductCore',
  }
</script>

<style lang="scss">
    .vm-core{
        position: relative;
        height: 700px;
        // NEST
        h2, p{ margin-bottom: 2rem !important; }

        h1{
            font-size: 64px;
            font-family: "Core Sans R 25", "Roboto", sans-serif !important;
            font-weight: inherit;
            margin-bottom: 2rem !important;
        }        
    }
</style>